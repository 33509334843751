<template>
  <v-navigation-drawer stateless temporary fixed width="40vw" color="drawerBackground" v-model="show" right>
    <v-card tile v-if="currentUser" elevation="0" :light="!darkMode" :dark="darkMode" style="height: 100vh; width: 40vw">
      <v-card-title class="pl-4">
        <v-row no-gutters>
          <h3>{{ isEditing ? 'Rediger' + ' - ' + currentUser.name : 'Opret' }}</h3>
          <r-btn class="ml-auto" icon @click="close">
            <v-icon dark>mdi-close-thick</v-icon>
          </r-btn>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-6">
        <v-form v-model="isValid" ref="editUserRef">
          <v-tabs v-show="isEditing && !currentUser.staffId" color="activeButton" background-color="navbar" v-model="currentTab">
            <v-tab :key="0">Bruger </v-tab>
            <v-tab v-if="currentUser.roles && currentUser.roles.includes('staff')" :key="1">Elever</v-tab>
            <v-tab v-if="staffAccess !== 0 && currentUser.roles && currentUser.roles.includes('staff')" :key="2">Rettigheder</v-tab>
          </v-tabs>
          <v-tabs-items v-model="currentTab" class="transparent">
            <v-tab-item :key="0" class="pt-4">
              <v-row class="mb-4" no-gutters>
                <v-col cols="12" md="3" align-self="center">Navn:*</v-col>
                <v-col>
                  <v-text-field v-model="currentUser.name" hide-details="auto" :autofocus="!isEditing" outlined :rules="[rules.required]" dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mb-4" no-gutters>
                <v-col cols="12" md="3" align-self="center">Email:*</v-col>
                <v-col>
                  <v-text-field v-model="currentUser.email" outlined hide-details="auto" :rules="[rules.required]" dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mb-4" no-gutters>
                <v-col cols="12" md="3" align-self="center">Tlf. Nummer:*</v-col>
                <v-col>
                  <v-text-field v-model="currentUser.phoneNumber" :rules="[rules.required]" outlined hide-details="auto" dense></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="isUserStaff" class="mb-4" no-gutters>
                <v-col cols="12" md="3" align-self="center">CVR:</v-col>
                <v-col>
                  <v-text-field v-model="currentUser.cvr" outlined hide-details="auto" dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mb-4" no-gutters v-if="!isUserStaff && isAdmin">
                <v-col cols="12" md="3" align-self="center">Kørelærer:</v-col>
                <v-col>
                  <v-select v-model="currentUser.staffId" outlined hide-details dense :items="staffList" item-text="name" item-value="id" />
                </v-col>
              </v-row>
              <!-- <v-row class="mb-4" no-gutters v-if="!isUserStaff">
                <v-col cols="12" md="3" align-self="center">Teorihold:</v-col>
                <v-col>
                  <v-select
                    v-model="currentUser.theoryTeamId"
                    clearable
                    outlined
                    hide-details
                    dense
                    @change="setModuleListOnTeamChange"
                    :items="theoryTeamList"
                    item-text="name"
                    item-value="id"
                  />
                </v-col>
              </v-row> -->
              <v-row class="mb-4" no-gutters v-if="!isUserStaff">
                <v-col cols="12" md="3" align-self="center">Lektionsplan:</v-col>
                <v-col>
                  <v-select v-model="currentUser.moduleListId" clearable outlined hide-details dense :items="moduleLists" item-text="name" item-value="id" />
                </v-col>
              </v-row>
              <v-row class="mb-4" no-gutters>
                <v-col cols="12" md="3" :class="$vuetify.breakpoint.mobile ? 'mb-2' : ''" align-self="center">Kommentar:</v-col>
                <v-col>
                  <v-textarea v-model="currentUser.comment" outlined hide-details="auto" dense></v-textarea>
                </v-col>
              </v-row>

              <v-row v-if="currentUser.roles && isAdmin" class="mb-4" no-gutters>
                <v-col cols="12" md="3" align-self="center">Roller:</v-col>
                <v-col cols="6" md="3">
                  <v-checkbox color="activeButton" class="mt-0 pt-0" v-model="currentUser.roles" value="staff" label="Ansat" dense hide-details />
                </v-col>
                <v-col v-if="isAdmin" cols="6" md="3" align-self="center">
                  <v-checkbox color="activeButton" class="mt-0 pt-0" v-model="currentUser.roles" value="admin" label="Administrator" dense hide-details />
                </v-col>
              </v-row>
              <p class="text-caption">Felter med asterisk (*) er obligatoriske.</p>
            </v-tab-item>
            <v-tab-item :key="1" class="pt-4">
              <v-data-table v-if="currentUser.students && currentUser.students.length > 0" :items="currentUser.students" :headers="studentListHeader">
                <template #[`item.actions`]="{ item }" class="d-flex">
                  <div class="d-flex justify-end">
                    <r-btn icon delete @click.stop="removeStudentFromInstructor(item)">
                      <v-icon>mdi-close</v-icon>
                    </r-btn>
                  </div>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item :key="2" v-if="staffAccess !== 0" class="pt-4">
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Tilføj Kommentar" :value="1"></v-checkbox>
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Læs Elever" :value="2"></v-checkbox>
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Rediger Elever" :value="4"></v-checkbox>
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Tildel selv Elev " :value="8"></v-checkbox>
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Læs Ansatte" :value="16"></v-checkbox>
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Rediger Ansatte" :value="32"></v-checkbox>
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Læs Lektionsplan" :value="64"></v-checkbox>
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Rediger Lektionsplan" :value="128"></v-checkbox>
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Læs Køreskole" :value="256"></v-checkbox>
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Rediger Køreskole" :value="512"></v-checkbox>
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Læs Elever Uden Kørelærer" :value="1024"></v-checkbox>
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Læs Teorihold" :value="2048"></v-checkbox>
              <v-checkbox v-model="staffPermissions" hide-details="auto" :ripple="false" label="Rediger Teorihold" :value="4096"></v-checkbox>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row class="pl-4" no-gutters>
          <r-btn :block="$vuetify.breakpoint.mobile" class="mr-lg-4 mb-2" @click="show = false">Annuller</r-btn>
          <r-btn v-if="currentTab != 1" :block="$vuetify.breakpoint.mobile" creation :loading="isSaving" :disabled="!isValid" @click="saveCurrentUser">
            <v-icon left>mdi-check</v-icon>
            {{ isEditing ? 'Gem ændringer' : 'Opret' }}
          </r-btn>
        </v-row>
      </v-card-actions>
    </v-card>
    <confirmation-dialog ref="confirmationDialogRef" />
  </v-navigation-drawer>
</template>

<script>
import apiService from '@/services/apiService';
import { mapGetters } from 'vuex';

export default {
  name: 'CreateEditUser',
  data: () => ({
    hasModifyStudent: false,
    isUserStaff: false,
    staffList: [],
    currentTab: 0,
    orgainzationList: [],
    staffAccess: 0,
    staffPermissions: [],
    show: false,
    currentUser: {},
    isSaving: false,
    isValid: false,
    theoryTeamList: [],
    moduleLists: [],
    possiblePermissionValues: [4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0],
    rules: {
      required: (value) => !!value || 'Skal indeholde tekst',
    },
    studentListHeader: [
      {
        text: 'Navn',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: '',
        value: 'actions',
      },
    ],
  }),
  computed: {
    ...mapGetters(['isAdmin', 'isStaff', 'user', 'darkMode', 'currentOrgId']),
    isEditing() {
      return this.currentUser?.id ? true : false;
    },
    userEnabled: {
      get() {
        return !this.currentUser.disabled;
      },
      set(val) {
        this.currentUser.disabled = !val;
      },
    },
  },
  methods: {
    async editUser(userId, isUserStaff) {
      this.isSaving = false;
      this.staffPermissions = [];
      this.staffAccess = 0;
      this.isUserStaff = isUserStaff;
      this.currentTab = 0;
      if (isUserStaff) {
        this.currentUser = await apiService.getStaffById(userId);
        this.staffAccess = this.currentUser.staffOrganizationAccess[0];
        this.staffPermissions = this.$util.parseStaffAccessValue(this.staffAccess.organizationAccess, false);
      } else {
        this.currentUser = await apiService.getStudentById(userId);
        this.moduleLists = await apiService.getModulePlanList(this.currentOrgId);
      }
      if (this.currentUser.organizationId) {
        await this.getStaffListByOrgId(this.currentUser.organizationId);
      }
      this.show = true;
    },
    createUser() {
      this.isSaving = false;
      this.isUserStaff = false;
      this.currentTab = 0;
      this.getStaffListByOrgId(this.currentOrgId);
      this.currentUser = {
        name: '',
        organizationId: '',
        phoneNumber: '',
        staffId: this.user.id,
        email: '',
        disabled: false,
        roles: null,
      };
      this.show = true;

      apiService.getModulePlanList(this.currentOrgId).then((list) => {
        this.moduleLists = list;
      });
    },
    createStaff() {
      this.isUserStaff = true;
      this.currentUser = {
        name: '',
        phoneNumber: '',
        email: '',
        roles: ['staff'],
        cvr: '',
      };
      this.staffPermissions = [1, 64, 256];
      this.show = true;
    },
    async removeStudentFromInstructor(student) {
      let dialogResponse = await this.$refs.confirmationDialogRef.showDialog({
        title: 'Fjern elev fra kærelærer',
        studentName: student.name,
        body: `Ønsker du at fjerne denne elev fra ${this.currentUser.name}`,
        okBtn: 'Fjern',
      });
      if (dialogResponse) {
        await apiService.releaseStudent(student.id).then(() => {
          this.currentUser.students = this.currentUser.students.filter((x) => x.id != student.id);
        });
      }
      this.$refs.confirmationDialogRef.hideDialog();
    },
    async saveCurrentUser() {
      this.isSaving = true;
      if (this.currentUser.roles && this.currentUser.roles.length > 0) {
        this.currentUser.organizationId = this.currentOrgId;

        if (this.isEditing) {
          await apiService.updateStaff({ ...this.currentUser, students: 0 });
          await apiService.updateStaffOrganizationAccess({
            ...this.staffAccess,
            organizationAccess: this.staffPermissions.reduce(function (previousValue, currentValue) {
              return previousValue + currentValue;
            }),
          });
        } else {
          await apiService.updateStaff(this.currentUser);
          await apiService.addExistingStaffToOrganization({
            staffEmail: this.currentUser.email,
            organizationId: this.currentOrgId,
            organizationAccess: this.staffPermissions.reduce(function (previousValue, currentValue) {
              return previousValue + currentValue;
            }),
          });
        }
        this.$emit('user-updated');
        this.show = false;
        this.isSaving = false;
      } else {
        this.currentUser.organizationId = this.currentOrgId;
        await apiService
          .updateStudent(this.currentUser)
          .then(() => {
            this.show = false;
          })
          .finally(() => {
            this.isSaving = false;
          });
        this.$emit('user-updated');
      }
    },
    close() {
      this.$emit('user-updated');
      this.show = false;
    },
    async getOrganizationList() {
      this.orgainzationList = await apiService.getOrganizationList();
    },

    // async getTheoryTeamList() {
    //   this.isFetching = true;
    //   if (this.currentOrgId) this.theoryTeamList = await apiService.getTheoryTeamList(this.currentOrgId);
    //   this.isFetching = false;
    // },
    // setModuleListOnTeamChange(test) {
    //   this.currentUser.moduleListId = this.theoryTeamList.find((x) => x.id == test).moduleList.id;
    // },
    async getStaffListByOrgId(orgId) {
      this.staffList = [
        {
          id: null,
          name: 'Ingen',
        },
      ];
      let newStaffList = await apiService.getStaffListByOrgId(orgId);
      this.staffList.push(...newStaffList);
      this.currentUser.staffId = this.currentUser.staffId ?? this.staffList[0].id;
    },
    async hasOrganizationAccess() {
      this.hasModifyStudent = await apiService.checkOrganizationAccess(this.$organizationAccess.ModifyStudents);
    },
  },
  created() {
    this.getOrganizationList();
    // this.getTheoryTeamList();
    this.hasOrganizationAccess();
  },
  mounted() {},
};
</script>

<style>
</style>